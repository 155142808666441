<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-form-model-item label="用户id" prop="(userId">
                <a-input v-model="rowData.userId" placeholder="用户id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="角色id" prop="(roleId">
                <a-input v-model="rowData.roleId" placeholder="角色id"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editDealerManagerRole, selectByIdDealerManagerRole, addDealerManagerRole } from '../api/DealerManagerRoleApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                userId: [
                    { required: true, message: '请输入用户id', trigger: 'blur' }
                ],
                roleId: [
                    { required: true, message: '请输入角色id', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addDealerManagerRole(this.rowData) : await editDealerManagerRole(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
