/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerManagerRole = params => axios({
    url: '/api/dealer_manager_role/system/dealerManagerRole/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerManagerRole = params => axios({
    url: '/api/dealer_manager_role/system/dealerManagerRole/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerManagerRole = params => axios({
    url: '/api/dealer_manager_role/system/dealerManagerRole/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerManagerRole = params => axios({
    url:'/api/dealer_manager_role/system/dealerManagerRole/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerManagerRole = params => axios({
    url: '/api/dealer_manager_role/system/dealerManagerRole/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
